// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-info img, .site-favicon {
    margin: 0!important;
    padding: 0;
    border: none!important;
    box-shadow: none;
    /* background: white; */
    max-width: 120px;
    width: 50%;
    height: 50%;
    max-height: 20px;
    margin: 12px;
    border-radius: 4px;
    object-fit: fill !important;
}

.website-info img {
    width: auto !important;
    height: auto !important;
    max-height: 20px !important;
    border-radius: 2px !important;
}

.website-info, .site-name {
    width: -webkit-fill-available;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: top;
    font-size: .8rem !important;
    font-weight: 800 !important;
    margin: 0 !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.website-info {
    width:-moz-fit-content;
    width: fit-content;
    max-width: 240px;
    display:flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom:8px;
}`, "",{"version":3,"sources":["webpack://./src/components/website-info/website-info.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,UAAU;IACV,sBAAsB;IACtB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,2BAA2B;IAC3B,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;IAC7B,oBAAoB;IACpB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,2BAA2B;IAC3B,2BAA2B;IAC3B,oBAAoB;IACpB,4BAA4B;IAC5B,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".website-info img, .site-favicon {\n    margin: 0!important;\n    padding: 0;\n    border: none!important;\n    box-shadow: none;\n    /* background: white; */\n    max-width: 120px;\n    width: 50%;\n    height: 50%;\n    max-height: 20px;\n    margin: 12px;\n    border-radius: 4px;\n    object-fit: fill !important;\n}\n\n.website-info img {\n    width: auto !important;\n    height: auto !important;\n    max-height: 20px !important;\n    border-radius: 2px !important;\n}\n\n.website-info, .site-name {\n    width: -webkit-fill-available;\n    display: -webkit-box;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    align-items: top;\n    font-size: .8rem !important;\n    font-weight: 800 !important;\n    margin: 0 !important;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 1;\n}\n\n.website-info {\n    width:-moz-fit-content;\n    width: fit-content;\n    max-width: 240px;\n    display:flex;\n    column-gap: 8px;\n    justify-content: center;\n    align-items: center;\n    flex-direction: row;\n    margin-bottom:8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
