// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shimmer-skeleton {
    width: 100%;
    height: 200px;
    background: var(--color);
    opacity:.2;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 200px;
    animation: shimmer .5s ease-in-out infinite forwards !important;
    border-radius: 4px;
    top:0;
}

@keyframes shimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}

.card-wrapper {
    border: 1px solid #ddd; 
    border-radius: 23px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12); /* Adjust as needed */
}`, "",{"version":3,"sources":["webpack://./src/components/FeedCardLoader/FeedCardLoader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,wBAAwB;IACxB,UAAU;IACV,+FAA+F;IAC/F,4BAA4B;IAC5B,4BAA4B;IAC5B,+DAA+D;IAC/D,kBAAkB;IAClB,KAAK;AACT;;AAEA;IACI;QACI,6BAA6B;IACjC;IACA;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,2CAA2C,EAAE,qBAAqB;AACtE","sourcesContent":[".shimmer-skeleton {\n    width: 100%;\n    height: 200px;\n    background: var(--color);\n    opacity:.2;\n    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);\n    background-repeat: no-repeat;\n    background-size: 800px 200px;\n    animation: shimmer .5s ease-in-out infinite forwards !important;\n    border-radius: 4px;\n    top:0;\n}\n\n@keyframes shimmer {\n    0% {\n        background-position: -800px 0;\n    }\n    100% {\n        background-position: 800px 0;\n    }\n}\n\n.card-wrapper {\n    border: 1px solid #ddd; \n    border-radius: 23px;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12); /* Adjust as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
