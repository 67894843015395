// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#feedContainer {
       margin: auto;
    overflow: auto;
    padding: 24px;
}
.feed-container {
    column-gap: 12px;
    margin: auto;
    overflow: overlay;
    padding:64px;

}

.feed {
    margin : auto;
    padding:0px 64px 0px 64px;
    height: calc(100vh - 74px);
    /* scrollbar-width: thin; */
    overflow: auto;
}



/* For mobile devices */
@media (max-width: 600px) {
    .feed-container {
        column-count: 1;
    }

    .feed{
        padding:0px 32px 0px 32px;
    }
}

/* For tablet devices */
@media (min-width: 601px) and (max-width: 900px) {
    .feed-container {
        column-count: 2;
    }
    .feed{
        padding:0px 32px 0px 32px;
    }
}

/* For small desktops */
@media (min-width: 901px) and (max-width: 1200px) {
    .feed-container {
        column-count: 3;
    }
}

/* For large desktops */
@media (min-width: 1201px) {
    .feed-container {
        column-count: 4;
    }
    .feed{
        padding:0px 32px 0px 32px;
    }
}
@media (min-width: 1900px) {
    .feed-container {
        column-count: 5;
    }
}
@media (min-width: 2200px) {
    .feed-container {
        column-count: 6;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Feed/Feed.css"],"names":[],"mappings":"AAAA;OACO,YAAY;IACf,cAAc;IACd,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,0BAA0B;IAC1B,2BAA2B;IAC3B,cAAc;AAClB;;;;AAIA,uBAAuB;AACvB;IACI;QACI,eAAe;IACnB;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA,uBAAuB;AACvB;IACI;QACI,eAAe;IACnB;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA,uBAAuB;AACvB;IACI;QACI,eAAe;IACnB;AACJ;;AAEA,uBAAuB;AACvB;IACI;QACI,eAAe;IACnB;IACA;QACI,yBAAyB;IAC7B;AACJ;AACA;IACI;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["#feedContainer {\n       margin: auto;\n    overflow: auto;\n    padding: 24px;\n}\n.feed-container {\n    column-gap: 12px;\n    margin: auto;\n    overflow: overlay;\n    padding:64px;\n\n}\n\n.feed {\n    margin : auto;\n    padding:0px 64px 0px 64px;\n    height: calc(100vh - 74px);\n    /* scrollbar-width: thin; */\n    overflow: auto;\n}\n\n\n\n/* For mobile devices */\n@media (max-width: 600px) {\n    .feed-container {\n        column-count: 1;\n    }\n\n    .feed{\n        padding:0px 32px 0px 32px;\n    }\n}\n\n/* For tablet devices */\n@media (min-width: 601px) and (max-width: 900px) {\n    .feed-container {\n        column-count: 2;\n    }\n    .feed{\n        padding:0px 32px 0px 32px;\n    }\n}\n\n/* For small desktops */\n@media (min-width: 901px) and (max-width: 1200px) {\n    .feed-container {\n        column-count: 3;\n    }\n}\n\n/* For large desktops */\n@media (min-width: 1201px) {\n    .feed-container {\n        column-count: 4;\n    }\n    .feed{\n        padding:0px 32px 0px 32px;\n    }\n}\n@media (min-width: 1900px) {\n    .feed-container {\n        column-count: 5;\n    }\n}\n@media (min-width: 2200px) {\n    .feed-container {\n        column-count: 6;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
