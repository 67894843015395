// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-shadow {
    width: 99.5%;
    height: 99.5%;
    position: absolute;
    top: 0;
    left: 0;

    border-radius: 24px;
    transition: all .25s ease-in-out !important;
    aspect-ratio: 1 / 1;
}`, "",{"version":3,"sources":["webpack://./src/components/DropShadow/DropShadow.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,MAAM;IACN,OAAO;;IAGP,mBAAmB;IACnB,2CAA2C;IAC3C,mBAAmB;AACvB","sourcesContent":[".drop-shadow {\n    width: 99.5%;\n    height: 99.5%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    -webkit-border-radius: 24px;\n\n    border-radius: 24px;\n    transition: all .25s ease-in-out !important;\n    aspect-ratio: 1 / 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
